<template>
    <div class="text-center" v-if="!is_data_ready">
       <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
    </div>
    <div class="tab-pane fade show active text-center" v-else-if="team1.length==0 && team2.length==0  && is_data_ready">
        <p>{{ $t("No match data found") }}.</p>
    </div>
    <div class="section-match-info-container" v-else>
        <div class="section-match-info-box">
            <div class="cr-submain-tab">
               <ul>
                    <li :class="(team2_type == 'all') ? 'active' : ''"><a :class="(team2_type == 'all') ? 'selected' : ''" href="javascript:;" @click=" team2_type = 'all'; getHistory()"> {{ $t("cric.All") }}</a></li>
                    <li :class="(team2_type == 'home') ? 'active' : ''"><a :class="(team2_type == 'home') ? 'selected' : ''" href="javascript:;" @click=" team1_type='home',team2_type = 'home'; getHistory()">{{ $t("cric.Home") }}</a></li>
                    <li :class="(team2_type == 'away') ? 'active' : ''"><a :class="(team2_type == 'away') ? 'selected' : ''" href="javascript:;" @click="team1_type='away', team2_type = 'away'; getHistory()">{{ $t("cric.Away") }}</a></li>
                </ul>
            </div>
            <div v-if="typeof team1!=='undefined' || team1.length!=0">
              <div class="cd_cr_hdg" v-if="team1 && team1.length>0 && team1['0']['team1']"><h2>{{team1['0']['team1']['name']}} {{ $t("cric.Last Games") }}</h2></div>
              <div class="cd_cr_list" v-for="team1_matches in team1" :key="team1_matches.id">
                  <div class="cd_cr">
                      <div class="cd_cr_lft">
                      <router-link :to="{ name: 'matchDetail.scorecard', params: { slug: team1_matches.slug, id: team1_matches.id } }" class="wrap-link-all" aria-label="scorecard"></router-link>
                          <!-- <a class="wrap-link-all" href="#"></a> -->
                          <span :class="team1_matches.status=='Finished' ? 'cus-tag tag-green' : 'cus-tag tag-gray'" v-if="team1_matches.status">{{team1_matches.status}}</span>
                          <span class="date" v-if="team1_matches.matchtype">{{team1_matches.matchtype}}</span>
                          <span class="match-count" v-if="team1_matches.matchno">{{team1_matches.matchno}}</span>
                      </div>

                      <div class="cd_cr_cnt">
                          <ul class="mh-inf">
                              <li class="active">
                                  <span class="team-info">
                                      <span class="flag" v-if="team1_matches.team1.id"><img :src="$storageURL+`images/cricket/round/${team1_matches.team1.id}.webp`" alt="ICC" width="34" height="20" :onerror="$teamDefaultLogo()"></span>
                                      <span class="team-name" v-if="team1_matches.team1.name">{{team1_matches.team1.name}}</span>
                                  </span>
                                  <span class="score" v-if="team1_matches.ht_score">{{team1_matches.ht_score}}</span>
                              </li>
                              <li>
                                  <span class="team-info">
                                      <span class="flag" v-if="team1_matches.team2.id"><img :src="$storageURL+`images/cricket/round/${team1_matches.team2.id}.webp`" alt="ICC" width="34" height="20" :onerror="$teamDefaultLogo()"></span>
                                      <span class="team-name" v-if="team1_matches.team2.name">{{team1_matches.team2.name}}</span>
                                  </span>
                                  <span class="score" v-if="team1_matches.at_score">{{team1_matches.at_score}} </span>
                              </li>
                          </ul>
                          <div class="match-sts">
                              <p v-if="team1_matches.comment">{{team1_matches.comment}}</p>
                          </div>
                      </div>
                      <div class="cd_cr_rgt">
                          <ul>
                               <li v-if="team1_matches.slug"><span class="star" :class="($store.getters.isMatchFavourited(team1_matches.slug)) ? 'active' : '1'" @click="toggleFavourite(team1_matches.slug,team1_matches.team1.name)" href="javascript:;"></span></li>
                              <li><span class="graph"></span></li>
                          </ul>
                      </div>
                  </div>
              </div>
            </div>
            <div v-if="typeof team2!=='undefined' || team2.length!=0">
              <div class="cd_cr_hdg" v-if="team2 && team2.length>0 && team2['0']"><h2>{{team2['0']['team2']['name']}} {{ $t("cric.Last Games") }}</h2></div>
              <div class="cd_cr_list" v-for="team2_matches in team2" :key="team2_matches.id">
                  <div class="cd_cr">
                      <div class="cd_cr_lft">
                       <router-link :to="{ name: 'matchDetail.scorecard', params: { slug: team2_matches.slug, id: team2_matches.id } }" aria-label="scorecard" class="wrap-link-all"></router-link>
                          <!-- <a class="wrap-link-all" href="#"></a> -->
                          <span class="cus-tag tag-green" v-if="team2_matches.status">{{team2_matches.status}}</span>
                          <span class="date" v-if="team2_matches.matchtype">{{team2_matches.matchtype}}</span>
                          <span class="match-count" v-if="team2_matches.matchno">{{team2_matches.matchno}}</span>
                      </div>

                      <div class="cd_cr_cnt">
                          <ul class="mh-inf">
                              <li class="active">
                                  <span class="team-info">
                                      <span class="flag" v-if="team2_matches.team2.id"><img :src="$storageURL+`images/cricket/round/${team2_matches.team2.id}.webp`" alt="ICC" width="34" height="20" :onerror="$teamDefaultLogo()"></span>
                                      <span class="team-name" v-if="team2_matches.team2.name">{{team2_matches.team2.name}}</span>
                                  </span>
                                  <span class="score" v-if="team2_matches.ht_score">{{team2_matches.at_score}}</span>
                              </li>
                              <li>
                                  <span class="team-info">
                                      <span class="flag" v-if="team2_matches.team1.id"><img :src="$storageURL+`images/cricket/round/${team2_matches.team1.id}.webp`" alt="ICC" width="34" height="20" :onerror="$teamDefaultLogo()"></span>
                                      <span class="team-name" v-if="team2_matches.team1.name">{{team2_matches.team1.name}}</span>
                                  </span>
                                  <span class="score" v-if="team2_matches.at_score">{{team2_matches.ht_score}}</span>
                              </li>
                          </ul>
                          <div class="match-sts">
                              <p v-if="team2_matches.comment">{{team2_matches.comment}}</p>
                          </div>
                      </div>
                      <div class="cd_cr_rgt">
                          <ul>
                              <li v-if="team2_matches.slug"><span class="star" :class="($store.getters.isMatchFavourited(team2_matches.slug)) ? 'active' : '1'" @click="toggleFavourite(team2_matches.slug,team2_matches.team2.name)" href="javascript:;"></span></li>
                              <li><span class="graph"></span></li>
                          </ul>
                      </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "history",

        data() {
            return {
                matchHistory: null,
                team1:null,
                team2:null,
                match: null,
                match_slug:null,
                team1_type: 'all',
                team2_type: 'all',
                active_team:'',
            }
        },
        mounted() {
             this.match_slug=this.$route.params.slug;
            this.getHistory()
        },
        serverPrefetch() {
            return this.getHistory();
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                '%HOME_TEAM%': this.$store.getters.getMatchDetailResponse.team1.name,
                '%AWAY_TEAM%': this.$store.getters.getMatchDetailResponse.team2.name,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_history_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_history_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.cricket_match_history_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        methods: {
            getHistory() {
                this.is_data_ready = false;
                return this.$axios
                    .get(this.$MATCH_HISTORY_API.replace(':match', this.$route.params.slug), {
                        params: {
                            home_type: this.team1_type,
                            away_type: this.team2_type,
                        }
                    })
                    .then(response => {
                        if (response.data) {
                            this.matchHistory = response.data.result;
                            this.team1=this.matchHistory.team1;

                              this.team2=this.matchHistory.team2;
                              //  console.log(this.team2);
                            this.is_data_ready = true;
                        }
                    })
            },
            toggleFavourite(matchSlug,teame_name) {
              this.active_team=teame_name;
               this.$store.commit('loadFileDetails', this.active_team);
                this.$store.commit(this.$store.getters.isMatchFavourited(matchSlug) ? 'removeFavouriteMatch' : 'addFavouriteMatch', matchSlug);
                this.$emit('toggleFavourite', matchSlug);
            }
        },

    }
</script>
